import alphaImg from '../assets/icecreamPics/alpha.jpg';
import aquasImg from '../assets/icecreamPics/aquas.jpg';
import coolcowImg from '../assets/icecreamPics/coolcow.jpg';
import craftImg from '../assets/icecreamPics/craft.jpg';
import dairyqueenImg from '../assets/icecreamPics/dairyqueen.jpg';
import dolceneveImg from '../assets/icecreamPics/dolceneve.jpg';
import fatcatImg from '../assets/icecreamPics/fatcat.jpg';
import flourandcreamImg from '../assets/icecreamPics/flourandcream.jpg';
import hanksImg from '../assets/icecreamPics/hanks.jpg';
import jenisImg from '../assets/icecreamPics/jenis.jpeg';
import honeychildImg from '../assets/icecreamPics/honeychild.jpeg';
import magnoliasImg from '../assets/icecreamPics/magnolias.jpeg';
import mangobeachImg from '../assets/icecreamPics/mangobeach.jpeg';
import milkandsugarImg from '../assets/icecreamPics/milkandsugar.jpg';
import minutiImg from '../assets/icecreamPics/minuti.jpg';
import ritasImg from '../assets/icecreamPics/ritas.jpg';
import rocambolescImg from '../assets/icecreamPics/rocambolesc.jpg';
import smooshImg from '../assets/icecreamPics/smoosh.jpeg';
import sweetbriberyImg from '../assets/icecreamPics/sweetbribery.jpg';
import tinysImg from '../assets/icecreamPics/tinys.jpg';
import treatsofmexicoImg from '../assets/icecreamPics/treatsofmexico.jpeg';
import undergroundImg from '../assets/icecreamPics/underground.jpg';
import vanleeuwenImg from '../assets/icecreamPics/vanleeuwen.jpg';
import vanleeuwen2Img from '../assets/icecreamPics/vanleeuwen2.jpg';

export const iceCreamImagesBySlug = {
  'alpha': [{
    src: alphaImg,
    alt: '',
  }],
  'aquas': [{
    src: aquasImg,
    alt: '',
  }],
  'coolcow': [{
    src: coolcowImg,
    alt: '',
  }],
  'craft': [{
    src: craftImg,
    alt: '',
  }],
  'dairyqueen': [{
    src: dairyqueenImg,
    alt: '',
  }],
  'dolceneve': [{
    src: dolceneveImg,
    alt: '',
  }],
  'fatcat': [{
    src: fatcatImg,
    alt: '',
  }],
  'flourandcream': [{
    src: flourandcreamImg,
    alt: '',
  }],
  'hanks': [{
    src: hanksImg,
    alt: '',
  }],
  'honeychild': [{
    src: honeychildImg,
    alt: '',
  }],
  'jenis': [{
    src: jenisImg,
    alt: '',
  }],
  'magnolias': [{
    src: magnoliasImg,
    alt: '',
  }],
  'mangobeach': [{
    src: mangobeachImg,
    alt: '',
  }],
  'milkandsugar': [{
    src: milkandsugarImg,
    alt: '',
  }],
  'minuti': [{
    src: minutiImg,
    alt: '',
  }],
  'ritas': [{
    src: ritasImg,
    alt: '',
  }],
  'rocambolesc': [{
    src: rocambolescImg,
    alt: '',
  }],
  'smoosh': [{
    src: smooshImg,
    alt: '',
  }],
  'sweetbribery': [{
    src: sweetbriberyImg,
    alt: '',
  }],
  'tinys': [{
    src: tinysImg,
    alt: '',
  }],
  'treatsofmexico': [{
    src: treatsofmexicoImg,
    alt: '',
  }],
  'underground': [{
    src: undergroundImg,
    alt: '',
  }],
  'vanleeuwen': [{
    src: vanleeuwenImg,
    alt: '',
  },
  {
    src: vanleeuwen2Img,
    alt: '',
  }],
}