import React from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader } from './general/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { aboutModalSelector, filtersModalSelector } from '../selectors/modals';
import { setAboutModal, setFiltersModal } from '../actions/modals';
import { GREY_MEDIUM_DARK } from '../constants/cssVars';
import logo from '../assets/icons/scoop_in_the_loop.svg';


const ScoopInTheLoop = styled.img`

`

export const FiltersModal = () => {
  const showModal = useSelector(filtersModalSelector);
  const dispatch = useDispatch();

  if (!showModal) {
    return null;
  }
  
  return (
    <Modal onClose={() => dispatch(setFiltersModal(false))}>
      <ScoopInTheLoop src={logo} alt="Scoop in the Loop" />
      <h3>About this site</h3>
    </Modal>
  )
}
