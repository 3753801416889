import { MAX_DESKTOP_WIDTH } from "./constants/cssVars";

export const statusIsGood = (status) => status >= 200 && status < 300;
export const statusIsUnauthorized = (status) => status === 401;

export const stringIsEmptyOrNull = (str) => str == null || str === ''

export const getSlugFromName = (str) => str ? str.toLowerCase().split(' ').join('_') : null;

export const getLocalStorageItem = (key) => {
  try {
    const result = localStorage.getItem(key);
    return result;
  } catch {
    return null;
  }
}

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch {
    // since we only use this as a backup to in-memory state, don't need additional action on error
  }
}

// Connects two items by "&", and 3+ items by "__, __, ... __, & __"
export const prettyEnglishList = (strList, allLowerCase) => {
  if (!strList || strList.length === 0) {
    return null;
  }
  if (strList.length === 1) {
    return strList[0];
  }
  // make a new list where all but the first item are converted to lowercase
  const newList = strList.map((item, idx) => {
    if (idx === 0 && !allLowerCase) {
      return item;
    }
    return item.toLowerCase();
  })
  const last = newList.pop();
  return newList.join(', ') + ' & ' + last;
}

export const getRandomInt = (max) => Math.floor(Math.random() * max);

export const makeRandomId = (length) => {
  var result           = '';
  var characters       = '0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
 }
 return result;
}

export const onMobile = () => {
  const mobileSystemDetected = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const mobileScreenWidth = window.innerWidth < MAX_DESKTOP_WIDTH;
  return mobileSystemDetected || mobileScreenWidth;
};