import React from 'react';
import styled from 'styled-components';
import { MAX_DESKTOP_WIDTH, SIDEBAR_WIDTH } from '../../constants/cssVars';
import { useCurrentIceCreamStore } from '../../hooks/useCurrentIceCreamStore';
import { useFilteredIceCreamStores } from '../../hooks/useFilteredIceCreamStores';
import { SidebarFilters } from './SidebarFilters';
import { SidebarSearchInput } from './SidebarSearchInput';
import { SidebarStoreList } from './SidebarStoreList';
import { SidebarStoreView } from './SidebarStoreView';
import waveBottom from '../../assets/icons/wave_bottom.svg';
import { LinkLikeButton } from '../general/Button';
import { useDispatch, useSelector } from 'react-redux';
import { clearSearchAndFilters } from '../../actions/general';
import { onMobile } from '../../utils';
import { showSidebarSelector } from '../../selectors/general';

// Neighborhood search
// Filter by scoop rating
// Options to show different layers

const SidebarTopSection = styled.div`
  box-shadow: 0 2px 2px rgba(0,0,0,.1);
  background-color: #D5EBE1;
  position: relative;
  z-index: 1;
`

const Intro = styled.div`
  font-size: 0.95em;
  font-style: italic;
  margin-bottom: 10px;
  color: #0b352a;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    padding-right: 110px;
    margin-bottom: 20px;
    padding-left: 5px;
  }  
`

const SidebarWrapper = styled.div`
  box-sizing: border-box;
  height: 100vh;
  width: ${SIDEBAR_WIDTH}px;
  background-color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  box-shadow: 0 4px 8px rgba(0,0,0,.3);
  z-index: 1000;
  overflow: auto;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: ${({ active, showMapUnderneath }) => active || showMapUnderneath ? 'block' : 'none'};
    position: ${({ showMapUnderneath }) => showMapUnderneath ? 'relative' : 'absolute'};
    top: 0px;
    width: 100%;
  }  
`

const NumberDisplay = styled.div`
  text-align: center;
  font-style: italic;
  color: #858585;
  background-color: #fff;
  padding-bottom: 10px;
  font-size: 0.9em;

  > img {
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: block;
    padding-bottom: 5px;
  }
`

export const Sidebar = () => {
  const dispatch = useDispatch();
  const currentIceCreamStore = useCurrentIceCreamStore();
  const [filteredStores, filteringActive] = useFilteredIceCreamStores();
  const showSidebar = useSelector(showSidebarSelector);

  // if there's a current store, show the individual store view:
  if (currentIceCreamStore != null) {
    return <SidebarWrapper active={showSidebar ? 1 : 0}>
      <SidebarStoreView />
    </SidebarWrapper>
  }

  // Otherwise, show the full list view
  return (
    <SidebarWrapper active={showSidebar ? 1 : 0}>
      <SidebarTopSection>
        <div style={{ padding: '20px', paddingBottom: '10px' }}>
          <Intro>
            A selection of Houston's bike accessible ice cream shops, as rated by two sweet-toothed cyclists.
          </Intro>
          <SidebarSearchInput />
          <SidebarFilters />
        </div>
        <NumberDisplay>
          <img src={waveBottom} alt="" />
          Displaying {filteredStores.length} store{filteredStores.length === 1 ? '' : 's'}{filteringActive && ', '}
          {filteringActive && (
            <LinkLikeButton onClick={() => dispatch(clearSearchAndFilters())} style={{ fontStyle: 'italic', color: '#34a78a' }}>
              reset filters
            </LinkLikeButton>
          )}
        </NumberDisplay>
      </SidebarTopSection>
      <SidebarStoreList />
    </SidebarWrapper>
  )
}