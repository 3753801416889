import { createStore, combineReducers } from 'redux';
import modalsReducer from './modals'
import generalReducer from './general'
import { CLEAR_STORE } from '../actions/general';

const reducer = combineReducers({
  modals: modalsReducer,
  general: generalReducer,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    return reducer(undefined, action)
  };
  return reducer(state, action);
}

const store = createStore(
  rootReducer
);

export default store;
