export const SET_ABOUT_MODAL = 'SET_ABOUT_MODAL';
export const SET_FILTERS_MODAL = 'SET_FILTERS_MODAL';

export const setAboutModal = (showModal) => {
  return { type: SET_ABOUT_MODAL, payload: showModal };
}

export const setFiltersModal = (showModal) => {
  return { type: SET_FILTERS_MODAL, payload: showModal };
}
