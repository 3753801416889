import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './reducers/store';
import { Main } from './components/Main';
import { CloseActiveDropdown } from './components/general/CloseActiveDropdown';

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Main />
      <CloseActiveDropdown />
    </BrowserRouter>
  </Provider>
);

export default App;