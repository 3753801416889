import React from 'react';
import styled from 'styled-components';
import { Modal } from './general/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { aboutModalSelector } from '../selectors/modals';
import { setAboutModal } from '../actions/modals';
import logo from '../assets/icons/scoop_in_the_loop_horiz.svg';
import { GREY_DARKEST, PRIMARY } from '../constants/cssVars';
import { TextLink } from './general/Common';

const ScoopInTheLoop = styled.img`
  width: max(50%, 300px);
`

const BlurbWrapper = styled.div`
  color: ${GREY_DARKEST};
  font-size: 0.92em;
  > p > a {
    color: ${PRIMARY};
  }
`

// If you need to update the blog link, here it is!
const BLOG_LINK = 'https://www.januaryadvisors.com/scoop-in-the-loop/'

export const AboutModal = () => {
  const showModal = useSelector(aboutModalSelector);
  const dispatch = useDispatch();

  if (!showModal) {
    return null;
  }
  
  return (
    <Modal onClose={() => dispatch(setAboutModal(false))}>
      <div style={{ textAlign: 'center'}}>
        <ScoopInTheLoop src={logo} alt="Scoop in the Loop" />
      </div>
      <BlurbWrapper>
        <p>
          Here in Houston, we're lucky to have ice cream weather year-round, and we've got a 
          great selection of ice cream parlors to allow you to develop a primo scoop rotation.
        </p>
        <p>
          <strong>So, where should you scoot in the loop to get the best scoop?</strong>
        </p>
        <p>
          We've done some intensive market research, visiting and scoring over 30 ice cream shops in central Houston. 
          We've ranked these spots on a 3-scoop scale. Some spots were off the charts and have earned a coveted 
          “3 scoops with a cherry on top” score.
        </p>
        <p>
          To really augment the childhood joy and nostalgia that ice cream brings, we recommend biking to these spots. 
          Luckily, Houston also has biking weather year-round, and a growing bike infrastructure network that can get you close to each of these ice cream shops. 
          <strong>{` `}In fact, each shop we've included here was <u>not</u> visited via car, but on 2 wheels by bike. </strong>
          Toggle on the bikeways layer of this map to plan your route to your next scoop!
        </p>
        <p>
          There are certainly scoops in the loop we've missed - some may be hard to access by bike, new, or off our radar entirely. 
          To suggest a new scoop, or dispute a scoop score, please fill out this <TextLink to="https://docs.google.com/forms/d/e/1FAIpQLScHWooDyhNhz1SJWCegaYV6rSNvqCza-Pr2CUjPSOmGNfRi0A/viewform" style={{ textDecoration: 'underline' }}>form</TextLink>, or email Shannon, our resident Super Scooper, at shannon@januaryadvisors.com.
        </p>
        <p style={{ fontStyle: 'italic', textAlign: 'center'}}>
          For more info, check out <TextLink to={BLOG_LINK}>Shannon's blog</TextLink>
        </p>
      </BlurbWrapper>
    </Modal>
  )
}
