import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from '../../actions/general';
import { searchStrSelector } from '../../selectors/general';

// Search string input for the sidebar
export const SidebarSearchInput = () => {
  const dispatch = useDispatch();
  const search = useSelector(searchStrSelector);
  return (
    <input 
      placeholder='Search stores'
      style={{ fontSize: '0.9em' }}
      onChange={(e) => dispatch(setSearch(e.target.value))} 
      value={search} />
  )
}