// Our main ice cream store data! From https://docs.google.com/spreadsheets/d/1ibW24vjUqa_0rFTwNDJvfuqJHjUsjjR8IBRt-TlJ81Y/edit#gid=0

export const iceCreamStores = [
  {
    name: "Alpha Desserts",
    slug: 'alpha',
    scoopRating: 3,
    address: '5711 Hillcroft St, Houston, TX 77036',
    coordinates: [29.720700925983003, -95.50006116931539],
    website: 'https://www.alphadesserts.com/',
    houstonLocal: 'y',
    images: ['alpha.jpg'],
    notes: 'The mango milkshake is a delightfully indulgent version of a mango lassi',
  },
  {
    name: "Amy's",
    slug: 'amys',
    scoopRating: 3,
    address: '3816 Farnham St, Houston, TX 77098',
    coordinates: [29.733641868898207, -95.4123633],
    website: 'https://amysicecreams.com/',
    houstonLocal: 'n',
    images: [],
    notes: "An Austin-based classic that serves the thickest ice cream I've ever had"
  },
  {
    name: "Aqua S",
    slug: 'aquas',
    scoopRating: 3,
    address: '506 Yale St Unit D, Houston, TX 77007',
    coordinates: [29.787940997705373, -95.3981177882273],
    website: 'https://aquas.us.com/',
    houstonLocal: 'n',
    images: ['aquas.jpg'],
    notes: "This place has some avant garde, instagram worthy ice cream. That doesn't come at a compromise to flavor though! The sea salt is totally refreshing, especially in the summer"
  },
  {
    name: "Ben & Jerry's",
    slug: 'benandjerrys',
    scoopRating: 2,
    address: '5515 Kirby Dr, Houston, TX 77005',
    coordinates: [29.722528994064383, -95.41829084299293],
    website: 'https://www.benjerry.com/houston',
    houstonLocal: 'n',
    images: [],
    notes: "One of the ice cream greats, but with so many good local spots, I'll stick with getting my Ben & Jerry's pints from the grocery store"
  },
  {
    name: "Chocolate Bar",
    slug: 'chocolatebar',
    scoopRating: 2,
    address: '2521 University Blvd, Houston, TX 77005',
    coordinates: [29.714917171069988, -95.41762282637882],
    website: 'https://www.theoriginalchocolatebar.com/',
    houstonLocal: 'y',
    images: [],
    notes: 'Better known for their cakes, Chocolate Bar also has a great assortment of ice cream'
  },
  {
    name: "Cloud 10",
    slug: 'cloud10',
    scoopRating: 1,
    address: '711A Heights Blvd, Houston, TX 77007',
    coordinates: [29.783757094478915, -95.39802423189967],
    website: 'https://cloud10creamery.com/',
    houstonLocal: 'n',
    images: [],
  },
  {
    name: "Cool Cow Creamery",
    slug: 'coolcow',
    scoopRating: 2,
    address: '609 Bradford Ave Ste 111, Kemah, TX 77565',
    coordinates: [29.543254374626784, -95.01793063190433],
    website: 'http://www.coolcowcreamery.com/',
    houstonLocal: 'y',
    images: [ 'coolcow.jpg'],
    notes: 'A totally refreshing treat to enjoy on the Kemah boardwalk!'
  },
  {
    name: "Craft Creamery",
    slug: 'craft',
    scoopRating: 3,
    address: '1338 Westheimer Rd Suite B, Houston, TX 77006',
    coordinates: [29.743685797059264, -95.3957042785271],
    website: 'https://craftcreameryhtx.com/',
    houstonLocal: 'y',
    images: [ 'craft.jpg'],
    notes: "Inventive flavors including Cacio e Pepe and Miso Caramel make this a spot you'll want to return to again and again"
  },
  {
    name: "Dairy Queen",
    slug: 'dairyqueen',
    scoopRating: 2,
    address: '3915 Old Spanish Trail, Houston, TX 77021',
    coordinates: [29.702907552474944, -95.35704160656117],
    website: 'https://dqtexas.com/',
    houstonLocal: 'n',
    images: [ 'dairyqueen.jpg'],
    notes: "That's what I like about Texas! The perfect road trip pit stop"
  },
  {
    name: "Dolce Neve",
    slug: 'dolceneve',
    scoopRating: 4,
    address: '4721 N Main St ste h, Houston, TX 77009',
    coordinates: [29.79785340905166, -95.38321685743686],
    website: 'https://www.dolcenevegelato.com/',
    houstonLocal: 'n',
    images: [ 'dolceneve.jpg'],
    notes: 'Just divine beyond words! I dream of the matcha marscapone'
  },
  {
    name: "Fat Cat Creamery",
    slug: 'fatcat',
    scoopRating: 3,
    address: '1901 N Shepherd Dr #3, Houston, TX 77008',
    coordinates: [29.803107410188478, -95.41068892024262],
    website: 'https://www.fatcatcreamery.com/',
    houstonLocal: 'y',
    images: [ 'fatcat.jpg'],
    notes: 'Fat Cat offers up the creamiest ice cream in town. Fun rotating seasonal flavors will keep you coming back again and again'
  },
  {
    name: "Fellini's",
    slug: 'fellini',
    scoopRating: 2,
    address: '5211 Kelvin Dr, Houston, TX 77005',
    coordinates: [29.71862401421081, -95.41650974934451],
    website: 'https://www.fellinihouston.com/',
    houstonLocal: 'y',
    images: [],
    notes: "A great spot for an affogato"
  },
  {
    name: "Flower & Cream",
    slug: 'flourandcream',
    scoopRating: 2,
    address: '2617 W Holcombe Blvd Suite B, Houston, TX 77030',
    coordinates: [29.705783433959308, -95.41918775521448],
    website: 'https://www.flowerandcream.com/our-flavors',
    houstonLocal: 'y',
    images: [ 'flourandcream.jpg'],
    notes: 'At this spot, you can choose your ice cream flavor AND your cone flavor, with options varying from classic sugar to blue corn'
  },
  {
    name: "Hank's Ice Cream Parlour",
    slug: 'hanks',
    scoopRating: 4,
    address: '9291 S Main St, Houston, TX 77025',
    coordinates: [29.684072504499664, -95.42199082822839],
    website: 'http://places.singleplatform.com/hanks-ice-cream-parlor/menu',
    houstonLocal: 'y',
    images: [ 'hanks.jpg'],
    notes: "Beyonce's favorite"
  },
  {
    name: "Honeychild's Sweet Creams",
    slug: 'honeychild',
    scoopRating: 3,
    address: '600 N Shepherd Dr Suite 450, Houston, TX 77007',
    coordinates: [29.783131994557127, -95.40868416134747],
    website: 'http://www.honeychildssweetcreams.com/',
    houstonLocal: 'y',
    images: [ 'honeychild.jpeg'],
    notes: 'Bold and fresh local flavors, and located in a fancy new bike-accessible market'
  },
  {
    name: "Jeni's",
    slug: 'jenis',
    scoopRating: 2,
    address: '375 W 19th St, Houston, TX 77008',
    coordinates: [29.803022431057617, -95.4034647318993],
    website: 'https://jenis.com/',
    houstonLocal: 'n',
    images: [ 'jenis.jpeg'],
    notes: "A nationwide favorite that just doesn't captivate me the way it does others"
  },
  {
    name: "Koffeteria",
    slug: 'koffeteria',
    scoopRating: 3,
    address: '1110 Hutchins St Suite 102, Houston, TX 77003',
    coordinates: [29.749905616546922, -95.3554665907959],
    website: 'https://koffeteria.com/',
    houstonLocal: 'y',
    images: [],
    notes: "Koffeteria does everything with effortless innovative fusion, and the rotating ice cream flavors are no exception!"
  },
  
  {
    name: "Magnolia's",
    slug: 'magnolias',
    scoopRating: 2,
    address: '6421 Brady St, Houston, TX 77011',
    coordinates: [29.741894746613625, -95.31050771901944],
    website: 'http://magnoliasicecreamandmore.com/',
    houstonLocal: 'y',
    images: [ 'magnolias.jpeg'],
    notes: "One of the original and best spots for raspas and mangonadas"
  },
  {
    name: "Mango Beach",
    slug: 'mangobeach',
    scoopRating: 2,
    address: '2302 White Oak Dr, Houston, TX 77009',
    coordinates: [29.780515306654177, -95.38237238650667],
    website: null,
    houstonLocal: 'y',
    images: [ 'mangobeach.jpeg'],
    notes: "A great stop for mangonadas"
  },
  {
    name: "Marble Slab",
    slug: 'marbleslab',
    scoopRating: 1,
    address: '2035 W Gray St, Houston, TX 77019',
    coordinates: [29.75263798651723, -95.41001156605356],
    website: 'https://www.order.marbleslab.com/menu/marble-slab-houston-w-gray',
    houstonLocal: 'n',
    images: [],
    notes: 'Quality, but so many local spots that do it better!'
  },
  {
    name: "Milk & Sugar",
    slug: 'milkandsugar',
    scoopRating: 3,
    address: '1848 Westheimer Rd, Houston, TX 77098',
    coordinates: [29.743166198045614, -95.40693223128827],
    website: 'http://www.milkandsugarcreamery.com/',
    houstonLocal: 'y',
    images: [ 'milkandsugar.jpg'],
    notes: 'A Montrose favorite with some amazing flavors, chief among them "rain or shine" - earl gray, lavendar, and a lemon base'
  },
  {
    name: "Minuti",
    slug: 'minuti',
    scoopRating: 2,
    address: '5903 Westheimer Rd, Houston, TX 77057',
    coordinates: [29.73762224301796, -95.48458191867161],
    website: 'http://minuticoffee.com/',
    houstonLocal: 'n',
    images: [ 'minuti.jpg'],
    notes: 'A great spot for an affogato'
  },
  {
    name: "Rita's",
    slug: 'ritas',
    scoopRating: 2,
    address: '2802 White Oak Dr, Houston, TX 77007',
    coordinates: [29.781879901562146, -95.38889434209491],
    website: 'http://www.ritasfranchises.com/HoustonHeights',
    houstonLocal: 'n',
    images: [ 'ritas.jpg'],
    notes: "The shaved ice layers make custard even more refreshing on a hot summer day"
  },
  {
    name: "Rocambolesc",
    slug: 'rocambolesc',
    scoopRating: 2,
    address: '1101 Uptown Park Blvd Suite 10, Houston, TX 77056',
    coordinates: [29.75662761547512, -95.45713738626944],
    website: 'https://rocambolesc.com/usa/en/',
    houstonLocal: 'n',
    images: [ 'rocambolesc.jpg'],
  },
  {
    name: "Smoosh",
    slug: 'smoosh',
    scoopRating: 1,
    address: '718 W 18th St, Houston, TX 77008',
    coordinates: [29.80156655599176, -95.4106003435561],
    website: 'https://www.smooshcookies.com/menu',
    houstonLocal: 'y',
    images: [ 'smoosh.jpeg'],
  },
  {
    name: "Sweet Bribery",
    slug: 'sweetbribery',
    scoopRating: 1,
    address: '250 W 19th St suite e, Houston, TX 77008',
    coordinates: [29.80276330142594, -95.40128696073492],
    website: 'http://www.sweetbribery.co/',
    houstonLocal: 'y',
    images: [ 'sweetbribery.jpg'],
  },
  {
    name: "Sweet Cup",
    slug: 'sweetcup',
    scoopRating: 1,
    address: '3939 Montrose Blvd, Houston, TX 77006',
    coordinates: [29.73715558101714, -95.3907363380295],
    website: 'https://www.sweetcupgelato.com/',
    houstonLocal: 'y',
    images: [],
  },
  {
    name: "Tiny's",
    slug: 'tinys',
    scoopRating: 3,
    address: '3636 Rice Boulevard, Houston, TX 77005',
    coordinates: [29.71713426754907, -95.43368392515434],
    website: 'https://www.tinyboxwoods.com/location/milk-and-cookies-west-university/',
    houstonLocal: 'y',
    images: [ 'tinys.jpg'],
    notes: "If you can resist the fresh-baked chocolate chip cookie for one visit, you won't be disappointed with the ice cream!"
  },
  {
    name: "Treats of Mexico",
    slug: 'treatsofmexico',
    scoopRating: 3,
    address: '724 Telephone Rd, Houston, TX 77023',
    coordinates: [29.734638981346713, -95.33098660306509],
    website: 'http://places.singleplatform.com/treats-of-mexico/menu',
    houstonLocal: 'y',
    images: [ 'treatsofmexico.jpeg'],
    notes: 'The concha ice cream sandwich is innovative and divine!'
  },
  {
    name: "Underground Creamery",
    slug: 'underground',
    scoopRating: 4,
    address: '4100 Montrose Blvd, Houston, TX 77006',
    coordinates: [29.789132947968884, -95.40954758466073],
    website: 'http://undergroundcreamery.com/',
    houstonLocal: 'y',
    images: [ 'underground.jpeg'],
    notes: "This online-only limited batch shop makes you work for you ice cream with a competive riddle. Each week brings a new set of flavors, loaded with a generous portion of sticky crunchy mixins. You'll become addicted to the ice cream and the competition",
  },
  {
    name: "Van Leeuwen",
    slug: 'vanleeuwen',
    scoopRating: 3,
    address: '2565 Amherst St Suite A, Houston, TX 77005',
    coordinates: [29.745018972500166, -95.39027470327335],
    website: 'https://vanleeuwenicecream.com/scoop-shops',
    houstonLocal: 'n',
    images: ['vanleeuwen.jpg', 'vanleeuwen2.jpg'],
    notes: "As if the base flavors at Van Leeuwen weren't good enough (hello, honeycomb), each shop collaborates with local chefs to bring local flavors. The Montrose location has a limited run flavor by Christine Ha of Xin Chao - gouda + marscapone base, chocolate chips, and a banana jam swirl. Bizarre but heavenly!"
  }
]