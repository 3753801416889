export const CLEAR_STORE = 'CLEAR_STORE';
export const SET_ACTIVE_DROPDOWN = 'SET_ACTIVE_DROPDOWN';
export const SET_SEARCH = 'SET_SEARCH'
export const SET_FILTERS = 'SET_FILTERS'
export const CLEAR_SEARCH_AND_FILTERS = 'CLEAR_SEARCH_AND_FILTERS'
export const SET_SIDEBAR = 'SET_SIDEBAR'

export const setActiveDropdown = (dropdownId) => {
  return { type: SET_ACTIVE_DROPDOWN, payload: dropdownId };
}

export const setSearch = (searchStr) => {
  return { type: SET_SEARCH, payload: searchStr };
}

export const setFilters = (filtersObj) => {
  return { type: SET_FILTERS, payload: filtersObj };
}

export const clearSearchAndFilters = () => {
  return { type: CLEAR_SEARCH_AND_FILTERS, payload: null };
}

export const setSidebar = (showSidebar) => {
  return { type: SET_SIDEBAR, payload: showSidebar };
}

// Tells the root reducer to completely clear the redux store, used for 401 encounters
export const clearStore = () => ({ type: CLEAR_STORE, payload: true })