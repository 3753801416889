import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../actions/general';
import { filtersSelector } from '../../selectors/general';

export const OptionsList = ({ hideSelectOne, options, name }) => {
  const fullOptions = [ ...(hideSelectOne ? [] : [{ value: "", label: "Select One" }]), ...options ];
  return fullOptions.map(option => (
    <option key={`${name}_${option.value}`} value={option.value}>
      {option.label}
    </option>)
  )
}

// Filters for sidebar list of stores
export const SidebarFilters = () => {
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector);
  return (
    <>
      <select style={{ marginTop: '10px', width: '49%', marginRight: '5px', fontSize: '0.9em', display: 'inline-block' }} 
          onChange={(e) => dispatch(setFilters({ scoops: e.target.value }))} 
          value={filters.scoops}>
        <OptionsList name="scoops" hideSelectOne={true} options={[
          { value: 'any', label: 'All scoops' },
          { value: '1', label: '1 scoop' }, 
          { value: '2', label: '2 scoops' }, 
          { value: '3', label: '3 scoops' },
          { value: '4', label: '3 scoops + cherry' }
        ]} />
      </select>
        <select style={{ marginTop: '10px', width: '49%', fontSize: '0.9em', display: 'inline-block' }} 
          onChange={(e) => dispatch(setFilters({ onlyHoustonLocalOwned: e.target.value }))} 
          value={filters.onlyHoustonLocalOwned}>
        <OptionsList name="onlyHoustonLocalOwned" hideSelectOne={true} options={[
          { value: 'false', label: 'All owners' },
          { value: 'true', label: 'Houston-local owned' }, 
        ]} />
      </select>
    </>
  )
}