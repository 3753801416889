import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import styled from 'styled-components';
import { MapContainer, TileLayer, Marker, Tooltip, useMap } from 'react-leaflet'
import { HEADER_HEIGHT, MAX_DESKTOP_WIDTH, SHADOW_CRISP, SIDEBAR_WIDTH } from '../../constants/cssVars';
import { useNavigate } from 'react-router-dom';
import { getMapIcon } from './MapIcon';
import { useCurrentIceCreamStore } from '../../hooks/useCurrentIceCreamStore';
import { onMobile } from '../../utils';
import { useFilteredIceCreamStores } from '../../hooks/useFilteredIceCreamStores';
import bikewaysJson from '../../data/existingBikeways.json';
import { Checkbox } from '../general/Checkbox';
import { iceCreamImagesBySlug } from '../../data/iceCreamImages';
// import notesIcon from '../../assets/icons/notes.svg';
import photoIcon from '../../assets/icons/photo.svg';
import { showSidebarSelector } from '../../selectors/general';
import { useSelector } from 'react-redux';
const MAPBOX_URL = 'https://api.mapbox.com/styles/v1/emikjackson/cl1p0udk8000814n29gjxke8x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZW1pa2phY2tzb24iLCJhIjoiY2toZjhmOW9iMG52YTJ4bHkyN21laHVpbCJ9.ac3r8JmRxMsCOc2n4HL_rg'

// https://www.freecodecamp.org/news/how-to-set-up-a-custom-mapbox-basemap-with-gatsby-and-react-leaflet/

// Need to flip coordinates for leaflet
const prepCoords = (coords) => coords
const HARRIS_COUNTY_COORDS = [29.75325090057295, -95.40112249555915];

const MapWrapper = styled.div`
  padding-left: ${SIDEBAR_WIDTH}px;
  width: 100%;
  width: calc(100% - ${SIDEBAR_WIDTH}px);
  position: relative;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    padding-left: 0px;
    display: ${({ active, showMapUnderneath }) => active || showMapUnderneath ? 'block' : 'none'};
    width: 100%;
  }  
`

const mapContainerStying = {
  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  minHeight: '20vh',
  position: 'relative',
  top: `${HEADER_HEIGHT}px`,
}

// Here's where the current hover card styling is! :)
const HoverCardContent = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  min-width: 190px;
  font-size: 1.3em;
  padding: 5px;
  position: relative;
  h3, p {
    margin: 0px !important;
    margin-bottom: 5px;
    white-space: normal;
  }
  h3 {
    position: relative;
    z-index: 1;
  }
`

const BikewaysCheckboxWrapper = styled.div`
  background-color: white;
  position: absolute;
  top: ${HEADER_HEIGHT + 10}px;
  left: calc(${SIDEBAR_WIDTH}px + 56px);
  z-index: 1000;
  box-shadow: ${SHADOW_CRISP};
  border-radius: 5px;
  padding: 10px;
  padding-right: 30px;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    left: 56px;
    padding: 5px;
  }  
`

const INIT_ZOOM = 12

const Icons = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  > img {
    width: 1.2em;
    height: 1.2em;
    margin: 5px;
    margin-left: 0px;
  }
`

// Card with information about the passed icecream store. 
// To be shown when a "MapMarker" is hovered over.
const MapMarkerHoverCard = ({ iceCreamStore }) => {
  return <Tooltip>
    <HoverCardContent>
      {/* 
        [DVT!] Any data we want to show in the hover card can be added here! :)
        Styling can be edited in "HoverCardContent" above
      */}
      <h3>{iceCreamStore.name}</h3>
      {iceCreamStore.scoopRating === 4
        ? <p>3 scoops + cherry</p>
        : <p>{iceCreamStore.scoopRating} scoop{iceCreamStore.scoopRating > 1 ? 's' : ''}</p>
      }
      <p>{iceCreamStore.address}</p>
      <Icons>
        {/* {iceCreamStore.notes && <img src={notesIcon} alt="store has notes" />} */}
        {iceCreamImagesBySlug[iceCreamStore.slug] && <img src={photoIcon} alt="store has photograph" />}
      </Icons>
    </HoverCardContent>
  </Tooltip>
}


// "Scoop" marker on the map that shows an info card when hovered
const MapMarker = ({ iceCreamStore, id }) => {
  const navigate = useNavigate();
  return ( 
    <Marker 
      position={prepCoords(iceCreamStore.coordinates)} 
      eventHandlers={{
        click: () => {
          navigate(`/stores/${id}`)
        }
      }}
      icon={getMapIcon(iceCreamStore)}>
      <MapMarkerHoverCard iceCreamStore={iceCreamStore} />
    </Marker>
  )
}


// Zooms the map to center on the currently selected ice cream store
const ZoomToCurrentStore = () => {
  const map = useMap();
  const currentIceCreamStore = useCurrentIceCreamStore();
  useEffect(() => {
    if (currentIceCreamStore != null) {
      map.flyTo(prepCoords(currentIceCreamStore.coordinates), 14, {
        animate: true,
      })
    } else {
      map.flyTo(prepCoords(HARRIS_COUNTY_COORDS), INIT_ZOOM, {
        animate: true,
      })
    }
  }, [currentIceCreamStore]) 
  return null;
}

// Create geojson layer to display bikeways
const LoadBikeways = ({ showBikeways }) => {
  const map = useMap();
  const [bikewayGeojsonLayer, setBikewayGeojsonLayer] = useState(null);

  useEffect(() => {
    if (bikewayGeojsonLayer != null) {
      map.removeLayer(bikewayGeojsonLayer);
    }

    if (showBikeways) {
      const bikewaysGeojson = new L.GeoJSON(bikewaysJson, {
        onEachFeature: (feature = {}, layer) => {
          const properties = feature.properties || {};
          layer.bindTooltip(`
            <div>
              <p><b>Bikeway</b></p>
              <p>Name: ${properties.Full_Name}</p>
              <p>Length: ${properties.Length_Miles} miles</p>
              <p>Type: ${properties.HC_Class}</p>
            </div>`, {className: 'bikeway-tooltip'})
        },
        style: () => ({
          weight: 4,
          opacity: 0.9,
          color: '#59a1e4',
        })
      })
      bikewaysGeojson.addTo(map)
      setBikewayGeojsonLayer(bikewaysGeojson);
    } else if (bikewayGeojsonLayer != null) {
      setBikewayGeojsonLayer(null);
    }
  }, [showBikeways])
}



// Overall map component displaying icecream stores as markers! :)
export const Map = () => {
  // This takes into account any filters (like search), so we only show the relevant stores on the map
  const [filteredStores] = useFilteredIceCreamStores();
  const currentIceCreamStore = useCurrentIceCreamStore();
  const [showBikeways, setShowBikeways] = useState(true);
  const showMap = !useSelector(showSidebarSelector);

  return (
    <MapWrapper active={showMap}>
      <MapContainer center={prepCoords(HARRIS_COUNTY_COORDS)} style={mapContainerStying} dragging={!onMobile()} zoom={INIT_ZOOM}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={MAPBOX_URL}
        />
        {/* For every store in our filtered list, we show a map marker! */}
        {filteredStores.map((iceCreamStore, index) => {
          return <MapMarker iceCreamStore={iceCreamStore} key={`store-${index}`} id={iceCreamStore.name} />
        })}
        <ZoomToCurrentStore />
        <LoadBikeways showBikeways={showBikeways} />
      </MapContainer>
      <BikewaysCheckboxWrapper>
        <Checkbox
          label={`Show Bikeways`}
          value="showBikeways"
          checked={showBikeways}
          onChange={() => setShowBikeways(!showBikeways)}
          wrapperStyle={{ fontSize: '0.9em' }}
            />
      </BikewaysCheckboxWrapper>
    </MapWrapper>
  )
}
