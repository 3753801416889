import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GREY_DARK, GREY_LIGHTER, GREY_MEDIUM_LIGHT } from '../../constants/cssVars';
import { useFilteredIceCreamStores } from '../../hooks/useFilteredIceCreamStores';
// import notesIcon from '../../assets/icons/notes.svg';
import photoIcon from '../../assets/icons/photo.svg';
import { iceCreamImagesBySlug } from '../../data/iceCreamImages';

const APPX_OTHER_SIDEBAR_CONTENT_HEIGHT = 262;

// Wrapper for the entire list that sets a maximum height & allows scrolling
const ListWrapper = styled.div`
  padding: 10px 15px 0px 15px;
  height: calc(100vh - ${APPX_OTHER_SIDEBAR_CONTENT_HEIGHT}px);
  border-bottom: 1px solid ${GREY_MEDIUM_LIGHT};
  background-color: ${GREY_LIGHTER};
  overflow: auto;
`

// Styling for an individual list item
const ListItem = styled.div`
  position: relative;
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,.12);
  margin-bottom: 10px;
  color: ${GREY_DARK};
  transition: all 0.1s linear;
  background-color: #fff;
  &:hover {
    box-shadow: 0 2px 8px rgba(0,0,0,.16);
  }
  h4, p {
    margin: 0px;
    margin-bottom: 5px;
  }
`

const Icons = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  > img {
    width: 1em;
    height: 1em;
    margin: 5px;
    margin-left: 0px;
  }
`

// List of icecream stores for the sidebar
// This list is filtered based on active search (and other filters later)
export const SidebarStoreList = () => {
  const [filteredStores] = useFilteredIceCreamStores();
  return (
    <ListWrapper>
      {filteredStores.map((iceCreamStore) => {
        const storeName = iceCreamStore.name;
        return <Link to={`/stores/${storeName}`} key={`list-item-${storeName}`} style={{ textDecoration: 'none' }}>
          <ListItem>
            <h4>{iceCreamStore.name}</h4>
            {iceCreamStore.scoopRating === 4
              ? <p>3 scoops + cherry</p>
              : <p>{iceCreamStore.scoopRating} scoop{iceCreamStore.scoopRating > 1 ? 's' : ''}</p>
            }
            <p>{iceCreamStore.address}</p>
            <Icons>
              {/* {iceCreamStore.notes && <img src={notesIcon} alt="store has notes" />} */}
              {iceCreamImagesBySlug[iceCreamStore.slug] && <img src={photoIcon} alt="store has photograph" />}
            </Icons>
          </ListItem>
        </Link>
      })}
    </ListWrapper>
  )
}