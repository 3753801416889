import React from 'react';
import styled from 'styled-components';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AboutModal } from './AboutModal';
import { FiltersModal } from './FiltersModal';
import { Map } from './map/Map';
import { Sidebar } from './sidebar/Sidebar';
import { Header } from './general/Header';

const Wrapper = styled.div`
  width: 100%;
`;

const NotFound = () => {
  return <h1>Page not found!</h1>
}

const MainContent = () => {
  return <>
    <Header />
    <Sidebar />
    <Map />
  </>
}

export const Main = () => {
  return (
    <Wrapper>
      <AboutModal />
      <FiltersModal />
      <Routes>
        <Route path='/' element={<MainContent />} />
        <Route path='/stores/:storeId' element={<MainContent />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Wrapper>
  )
}