import L from 'leaflet';
import fullCone1 from '../../assets/icons/full_cone_1.svg'
import fullCone2 from '../../assets/icons/full_cone_2.svg'
import fullCone3 from '../../assets/icons/full_cone_3.svg'
import fullCone4 from '../../assets/icons/full_cone_4.svg'
import fullCone1Shadow from '../../assets/icons/full_cone_1_shadow.svg'

const LeafIcon = L.Icon.extend({
  options: {}
});

// FIGMA DESIGN LINK https://www.figma.com/file/TW2FamVwumLqt7kGMqIQWI/Ice-Cream-App-Icons
// The heights for these images are pulled from the figma "H" property.
// The current heights are ...
// fullCone1 (full_cone_1): 402
// fullCone2 (full_cone_2): 546
// fullCone3 (full_cone_3): 670
// fullCone4 (full_cone_5): 725 - instead of an extra scoop, this one has sprinkles & cherry! :)

export const getMapIcon = (iceCreamStore) => {
  // [DVT!] Can add logic about which scoops to show here! :)

  let icon = fullCone1;
  let iconHeight = 402; // see heights comment above this function
  
  // We should update the following logic to use scoopRating instead of checking against the name!
  // You can view the format of the current data in "iceCreamStores.js"
  if (iceCreamStore.scoopRating === 2) {
    icon = fullCone2;
    iconHeight = 546; 
  } else if (iceCreamStore.scoopRating === 3) {
    icon = fullCone3;
    iconHeight = 670; 
  } else if (iceCreamStore.scoopRating === 4) {
    icon = fullCone4;
    iconHeight = 725; 
  }

  return new LeafIcon({
    iconUrl: icon,
    iconSize: [30, iconHeight / 8],
    iconAnchor: [16, iconHeight / 8],
    tooltipAnchor: [16, -30],
    shadowUrl: fullCone1Shadow,
    shadowSize: [32,50],
    shadowAnchor: [0, 35],
  });
}