import { CLEAR_SEARCH_AND_FILTERS, SET_ACTIVE_DROPDOWN, SET_FILTERS, SET_SEARCH, SET_SIDEBAR } from "../actions/general";

const defaultState = {
  activeDropdown: null,
  searchStr: '',
  showSidebar: false,
  filters: {
    scoops: 'any',
    onlyHoustonLocalOwned: 'false',
  }
}

const generalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVE_DROPDOWN:
      return {
        ...state,
        activeDropdown: action.payload,
      };
    case SET_SEARCH:
      return {
        ...state,
        searchStr: action.payload,
      };
    case SET_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        },
      };
    case CLEAR_SEARCH_AND_FILTERS:
      return {
        ...state,
        searchStr: '',
        filters: { ...defaultState.filters }
      }
    default:
      return state;
  }
};

export default generalReducer;