import React from 'react';
import styled from 'styled-components';
import { GREY_MEDIUM_LIGHT, GREY_DARK, SHADOW_CRISP, PRIMARY, MAX_DESKTOP_WIDTH } from '../../constants/cssVars';
import logo from '../../assets/icons/scoop_in_the_loop.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAboutModal } from '../../actions/modals';
import { clearSearchAndFilters, setSidebar } from '../../actions/general';
import { showSidebarSelector } from '../../selectors/general';
import { useCurrentIceCreamStore } from '../../hooks/useCurrentIceCreamStore';
import { useFilteredIceCreamStores } from '../../hooks/useFilteredIceCreamStores';

const HeaderWrapper = styled.div`
  // background: linear-gradient(to right, #fff, #ffe8eb);
  background-color: #fff;
  color: ${GREY_DARK};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  right: 0px;
  padding: 15px;
  border-radius: 0px 0px 0px 15px;
  z-index: 1001;
  position: fixed;
  box-shadow: ${SHADOW_CRISP};
  border-bottom: 1px solid ${GREY_MEDIUM_LIGHT};
  font-size: 0.95em;
  > img {
    width: 150px;
  }
`;

const MainNavWrapper = styled.div`
  display: block;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: ${({ hideOnMobile }) => hideOnMobile ? 'none' : 'block'};
  }  
`

const SlideOutButton = styled.button`
  top: 130px;
  right: 0px;
  padding: 5px 15px;
  border-radius: 20px 0px 0px 20px;
  z-index: 1001;
  position: fixed;
  font-size: 0.9em;
  color: #fff;
  font-weight: 600;
  background-color: ${PRIMARY};
  border: none;
  box-shadow: ${SHADOW_CRISP};
  cursor: pointer;
  transition: padding-right 0.2s linear;
  &:hover {
    padding-right: 25px;
  }
`

const MobileOnlySlideOutButton = styled(SlideOutButton)`
  display: none;
  top: ${({ top }) => top || 170}px;
  background-color: #73275C;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    display: block;
  }  
`


export const Header = () => {
  const dispatch = useDispatch();
  const showSidebar = useSelector(showSidebarSelector);
  const currentStore = useCurrentIceCreamStore();
  const [, filteringActive] = useFilteredIceCreamStores();

  return (
    <header>
      <MainNavWrapper hideOnMobile={showSidebar ? 1 : 0}>
        <nav>
          <Link to="/">
            <HeaderWrapper>
              <img src={logo} alt="Scoop in the Loop" />
            </HeaderWrapper>
          </Link>
        </nav>
        <SlideOutButton onClick={() => dispatch(setAboutModal(true))}>
          About this site
        </SlideOutButton>
        <MobileOnlySlideOutButton onClick={() => dispatch(setSidebar(true))}>
          View store {currentStore != null ? 'details' : 'list'}
        </MobileOnlySlideOutButton>
        {filteringActive && (
          <MobileOnlySlideOutButton top={210} onClick={() => dispatch(clearSearchAndFilters())}>
            Reset filters
          </MobileOnlySlideOutButton>
        )}
      </MainNavWrapper>
      {showSidebar && <MobileOnlySlideOutButton top={10} onClick={() => dispatch(setSidebar(false))}>
        Back to map
      </MobileOnlySlideOutButton>}
    </header>
  )
}