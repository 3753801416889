import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GREY_DARK, GREY_MEDIUM_DARK, GREY_MEDIUM_LIGHT } from '../../constants/cssVars';
import { iceCreamImagesBySlug } from '../../data/iceCreamImages';
import { useCurrentIceCreamStore } from '../../hooks/useCurrentIceCreamStore';
import { TextLink } from '../general/Common';
import waveTop from '../../assets/icons/wave_top.svg';
import waveBottom from '../../assets/icons/wave_bottom.svg';

const Wrapper = styled.div`
  padding: 20px;
  color: ${GREY_DARK};
  > h2 {
    margin-bottom: 0px;
  }
  > p {
    margin: 5px 0px;
  }
  > img {
    width: 100%;
    border: 1px solid ${GREY_MEDIUM_LIGHT};
    border-radius: 6px;
    margin-top: 10px;
  }
`

const WavyWrappedHeader = styled.div`
  width: calc(100% + 40px);
  margin: 10px -20px;

  > div {
    margin: 0px;
    padding: 10px 20px;
    background-color: #D5EBE1;
  }

  h2, p {
    margin: 0px;
  }
  
  > img {
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: block;
  }
` 

const removeTrailingSlash = (str) => {
  return str.replace(/\/+$/, '');
}

const removeHTTPS = (str) => {
  return str.replace(/(^\w+:|^)\/\//, '');
}

const formatWebsiteLinkForView = (str) => {
  return removeHTTPS(removeTrailingSlash(str))
}

// Store-specific view, with a back button to return to the full store list
export const SidebarStoreView = () => {
  const currentIceCreamStore = useCurrentIceCreamStore();
  const iceCreamPics = iceCreamImagesBySlug[currentIceCreamStore.slug];

  return (
    <Wrapper>
      <Link style={{ color: '#73275C' }} to="/">{`← View full list`}</Link>
      <WavyWrappedHeader>
        <img src={waveTop} alt="" />
        <div>
          <h2>{currentIceCreamStore.name}</h2>
          <p style={{ fontStyle: 'italic' }}>{currentIceCreamStore.address}</p>
        </div>
        <img src={waveBottom} alt="" />
      </WavyWrappedHeader>
      <p>
        Our rating: <strong>{currentIceCreamStore.scoopRating} Scoop{currentIceCreamStore.scoopRating > 1 ? 's' : ''}</strong>
      </p>
      <p>
        Website: {currentIceCreamStore.website 
                    ? <TextLink to={currentIceCreamStore.website}>{formatWebsiteLinkForView(currentIceCreamStore.website)}</TextLink>
                    : 'None'}
      </p>
      <p>
        Houston-local owned: {currentIceCreamStore.houstonLocal === 'y' ? 'Yes' : 'No'} 
      </p>
      {currentIceCreamStore.notes && <p>
        Shannon's notes: <span style={{ color: GREY_MEDIUM_DARK, display: 'block', marginTop: '5px', fontStyle: 'italic' }}>
          {currentIceCreamStore.notes}
          </span>
      </p>}
      {(Array.isArray(iceCreamPics) && iceCreamPics.length > 0) && iceCreamPics.map((iceCreamPic, idx) => 
          <img src={iceCreamPic.src} alt={iceCreamPic.alt} key={`${idx}-${currentIceCreamStore.slug}`} />
      )}
    </Wrapper>
  )
}