import { useParams } from 'react-router-dom';
import { iceCreamStores } from '../data/iceCreamStores';

// Return currently selected store object
export const useCurrentIceCreamStore = () => {
  // The "storeId" comes from a URL parameter - if a store has been selected, the URL will have the store id in it! :)
  const { storeId } = useParams();

  // We set our current ice cream store object by grabbing the store from our data that matches the current "storeId"
  const currentIceCreamStore = iceCreamStores.find((iceCreamStore) => iceCreamStore.name === storeId)

  return currentIceCreamStore;
}