import React from 'react';
import '../../style/loaders.css';
import styled from 'styled-components';

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgb(100,100,100,0.4);
`;

// Full-page loader, with circulating dots
export const PageLoader = () => (
  <OuterWrapper>
    <div className="lds-roller" data-testid="page-loader">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </OuterWrapper>
)

// Button loader, with animated dots in a line
export const ButtonLoader = () => (
  <div className="lds-ellipsis" data-testid="button-loader">
    <div />
    <div />
    <div />
    <div />
  </div>
)
