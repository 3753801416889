import styled from 'styled-components';

const CheckboxWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  ${({ wrapperStyle }) => wrapperStyle};
`

export const Checkbox = ({ value, onChange, checked, label, wrapperStyle }) => {
  return (
    <CheckboxWrapper wrapperStyle={wrapperStyle}>
      <input id={value} onChange={onChange} className="regular-checkbox" type="checkbox" checked={checked} value={value} />
      {label}
    </CheckboxWrapper>
  )
}