import { 
  SET_ABOUT_MODAL, 
  SET_FILTERS_MODAL
} from '../actions/modals';

const defaultState = {
  showAboutModal: true, 
  showFiltersModal: false,
}

const modalsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ABOUT_MODAL:
      return {
        ...state,
        showAboutModal: action.payload,
      };
    case SET_FILTERS_MODAL:
      return {
        ...state,
        showFiltersModal: action.payload,
      };
    default:
      return state;
  }
};

export default modalsReducer;