export const GREY_DARKEST = "#121212";
export const GREY_DARK = "#444444";
export const GREY_MEDIUM_DARK = "#686868";
export const GREY_MEDIUM = "#CECECE";
export const GREY_MEDIUM_LIGHT= "#dedede";
export const GREY_LIGHT = "#F0F0F0";
export const GREY_LIGHTER = "#F7F7F7";
export const GREY_LIGHTEST = "#FAFAFA";
export const WHITE = "#FFFFFF";
export const TABLE_GREY = "#E9E9E9";

export const PRIMARY = "#028F6B";
export const PRIMARY_LIGHT = '#6BC8B0';
export const PRIMARY_DARK = '#06624A';
export const SECONDARY = "#2B40A2";
export const SECONDARY_LIGHT = "#7284D6";
export const TERTIARY = "#4F2489";
export const TERTIARY_LIGHT = "#8968B6";
export const HIGHLIGHT = "#FFC20A";
export const ERROR = "#BD2D2D";

export const BG_LIGHTER = "#F9FAFA";
export const BG_PRIMARY = '#F2F6F6'; 

export const SHADOW_MEDIUM = "0 3px 6px rgba(0,0,0,.14)";
export const SHADOW_LIGHT = "0 2px 2px rgba(0,0,0,.06)";
export const SHADOW_CRISP = "0 2px 4px rgba(0,0,0,.12)";
export const SHADOW_DARK= "0 4px 8px rgba(0,0,0,.2)";

export const HEADER_HEIGHT = 0;
export const MAIN_CONTENT_WIDTH = 800;
export const MAX_DESKTOP_WIDTH = MAIN_CONTENT_WIDTH + 20;

export const SIDEBAR_WIDTH = 320;