
// Takes in the current search string (and later other filters)

import { useSelector } from "react-redux"
import { iceCreamStores } from "../data/iceCreamStores";
import { filtersSelector, searchStrSelector } from "../selectors/general"

// to filter down ice cream stores for display on the sidebar & map.
export const useFilteredIceCreamStores = () => {
  // Current inputted search string (from sidebar)
  const searchStr = useSelector(searchStrSelector);

  // Current filters
  const filters = useSelector(filtersSelector);

  // Filter down our full iceCreamStores list
  const filteredStores = iceCreamStores.filter(store => filters.scoops === 'any' || `${store.scoopRating}` === filters.scoops)
                                       .filter(store => filters.onlyHoustonLocalOwned === 'false' || store.houstonLocal === 'y')
                                       .filter(store => store.name.toLowerCase().includes(searchStr.toLowerCase()))

  const filteringActive = filteredStores.length !== iceCreamStores.length;
  return [filteredStores, filteringActive];
}